import React from "react"

// CSS Modules
import calendarStyles from "../styles/calendar.module.styl"
import info from "../styles/info.module.styl";

const CalendarDate = ({ day, month, year }) => (
  <div className={calendarStyles.date}>
    <div className={calendarStyles.month}>{month}</div>
    <div className={calendarStyles.day}>{day}</div>
    <div className={calendarStyles.year}>{year}</div>
  </div>
);


class CalendarItem extends React.Component{
  render() {
    const {time, eventName, venue} = this.props;

    return(
      <div
        className={calendarStyles.calendarItem}
      >
        <CalendarDate {...this.props} />
        <p className={calendarStyles.time}>{time}</p>

        <div className={calendarStyles.event}>
          <h1 className={calendarStyles.name}>{eventName}</h1>
          <h2 className={calendarStyles.place}>{venue}</h2>
        </div>

      </div>
    )
  }
}

class HomeCalendarGrid extends React.Component{
  render() {

    return(
      <div
        className={calendarStyles.calendarGrid}
      >
        <CalendarItem
          day="22" month="octubre" year="2019" time="14:00 p.m."
          eventName="Presentación - Évelyn"
          venue="Casa Tomada"
        />
        <CalendarItem day="27" month="octubre" year="2019" time="14:00 p.m."
          eventName="Feria del libro"
          venue="Profética"
        />
        <CalendarItem
          day="19" month="noviembre" year="2019" time="14:00 p.m."
          eventName="Otra presentación de otra cosa"
          venue="Marabunta"
        />
        <CalendarItem
          day="20" month="diciembre" year="2019" time="14:00 p.m."
          eventName="Otra feria del libro"
          venue="Impronta"
        />
        <CalendarItem
          day="22" month="octubre" year="2019" time="14:00 p.m."
          eventName="Presentación - Évelyn"
          venue="Casa Tomada"
        />
        <CalendarItem day="27" month="octubre" year="2019" time="14:00 p.m."
                      eventName="Feria del libro"
                      venue="Profética"
        />
        <CalendarItem
          day="19" month="noviembre" year="2019" time="14:00 p.m."
          eventName="Otra presentación de otra cosa"
          venue="Marabunta"
        />
      </div>
    )
  }
}

export class InfoSection extends React.Component {
  render() {
    const {header, content, subheader} = this.props;

    return (
      <div className={info.sectionContainer}>
        <div className={info.header}>
          <h4>{header}</h4>
          {subheader ? <h5>{subheader}</h5> : ""}
        </div>
        <div className={info.list}>
          {content}
        </div>
      </div>
    );
  }
}


export default HomeCalendarGrid

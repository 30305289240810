import React from "react";

import {MainLayout} from "../components/layout"
import {SectionHeader} from "./index";
import {InfoSection} from "../components/info";

import shared from "../styles/shared.module.styl";
import info from "../styles/info.module.styl";
import { Helmety } from "../components/Head";


export default class Contacto extends React.Component {
  render () {

    return (
      <MainLayout>
        <Helmety
          url="https://www.dharmabooks.com.mx/contacto/"
          title="Contacto - Dharma Books"
          content="Dharma Books es una editorial que quiere crecer a la par de lo que la literatura y la poesía nos lo permitan."
          imgURL="https://www.dharmabooks.com.mx/static_resources/logos/griso_sl.png"
        />
        <div className={shared.container}>
          <div className={shared.content}>
            <SectionHeader header="Contacto" />
            <InfoSection
              header="Dharma Books México"
              subheader="(Departamento de edición)"
              content={(<React.Fragment>
                <p className={info.infoListItem}>Arquitectos 51</p>
                <p className={info.infoListItem}>Col. Escandón.</p>
                <p className={info.infoListItem}>Miguel Hidalgo</p>
                <p className={info.infoListItem}>C.P. 11800. CDMX</p>
                <p>L-V 10:00 - 19:00</p>
                <a href="mailto:contacto@dharmabooks.com.mx">contacto@dharmabooks.com.mx</a>
                <iframe
                  title="dharma-mx"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3763.2674966502004!2d-99.17442438474535!3d19.40084494672184!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1ff6c14035c71%3A0x2e4b0eee583b2a81!2sArquitectos%2051%2C%20Escand%C3%B3n%20II%20Secc%2C%20Miguel%20Hidalgo%2C%2011800%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX%2C%20M%C3%A9xico!5e0!3m2!1ses!2sus!4v1574826365569!5m2!1ses!2sus"
                  width="400" height="300" frameBorder="0" style={{border: 0}} allowFullScreen=""></iframe>
              </React.Fragment>)} />
            <InfoSection
              header="Dharma Books Los Ángeles"
              subheader="(Departamento de arte)"
              content={(<React.Fragment>
                <p className={info.infoListItem}>426 S Sepulveda Blvd</p>
                <p className={info.infoListItem}>Los Angeles, CA.</p>
                <p className={info.infoListItem}>90049</p>
                <p>L-V 10:00 - 19:00 (Hora del Pacífico)</p>
                <a href="mailto:raul@dharmabooks.com.mx">raul@dharmabooks.com.mx</a>
                <iframe
                  title="dharma-la"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3305.1098736479125!2d-118.46250888478457!3d34.06669758060207!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2bc97a138999b%3A0xb03f0b35c648f669!2s426%20S%20Sepulveda%20Blvd%2C%20Los%20Angeles%2C%20CA%2090049!5e0!3m2!1ses!2sus!4v1610321584759!5m2!1ses!2sus"
                  width="400" height="300" frameBorder="0" style={{border: 0}} allowFullScreen="" aria-hidden="false"></iframe>

              </React.Fragment>)} />
          </div>
        </div>
      </MainLayout>
    )
  }
}

